/* global google */
import React, { useEffect, useRef, useMemo, useState } from 'react';
import ThreeDMap from './threeDMap';
import StreetMap from './streetMap';

import '../../css/map.css';

const Map = () => {
//   const mapRef = useRef(null);
  const streetMapRef = useRef(null);
  const ThreeDMapUpRef = useRef(null);
  const ThreeDMapDownRef = useRef(null);

  const [map3DElementUp, setMap3DElementUp] = useState(null);
  const [map3DElementDown, setMap3DElementDown] = useState(null);

  const defaultPosition = { lat: 35.658, lng: 139.745, alt: 100 };
  const memoizedDefaultPosition = useMemo(() => ({
    lat: defaultPosition.lat,
    lng: defaultPosition.lng,
    alt: defaultPosition.alt,
  }), [defaultPosition.lat, defaultPosition.lng, defaultPosition.alt]);

  useEffect(() => {
  
    // create streetview element
    const panorama = new google.maps.StreetViewPanorama(
      document.getElementById("pano"),
      {
        position: { lat: memoizedDefaultPosition.lat, lng: memoizedDefaultPosition.lng },
        pov: {
          heading: 30,
          pitch: 0,
        },
        disableDefaultUI: true,
        zoom: 0,
      },
    );

    const mapElementUp = ThreeDMapUpRef.current.querySelector('gmp-map-3d');
    setMap3DElementUp(mapElementUp);
    const mapElementDown = ThreeDMapDownRef.current.querySelector('gmp-map-3d');
    setMap3DElementDown(mapElementDown);

    // animate map_left and map_right

    // 设置定时器更新地图旋转
    const intervalMapMove = setInterval(() => {
      if (map3DElementUp && map3DElementDown) {
        const newCenter = {
          lat: map3DElementUp.center.lat + 0.00002,
          lng: map3DElementUp.center.lng + 0.00002,
          altitude: map3DElementUp.center.altitude,
        };
        map3DElementUp.center = newCenter;
        map3DElementDown.center = newCenter;
        // console.log('Updated center:', newCenter);
      }
    }, 75);

    // 设置定时器更新map-center
    const intervalStreetPov = setInterval(() => {
      if (panorama) {
        // const newPov = {
        //   heading: panorama.pov.heading + 10,
        //   pitch: panorama.pov.pitch,
        // };
        // panorama.setPov(newPov);
        // console.log('Updated pov:', newPov);
        const newZoom = panorama.getZoom() + 0.05;
        panorama.setZoom(newZoom);
        // console.log('Updated zoom:', newZoom);
        }
      }, 200);
    const intervalStreetPosition = setInterval(() => {
      if (panorama) {
        const newPosition = { 
          lat: map3DElementUp.center.lat, 
          lng: map3DElementUp.center.lng 
        };
        panorama.setPosition(newPosition);
        panorama.setZoom(0);
        const location = panorama.getLocation();
        if (location && location.description) {
          streetMapRef.current.handleChangeText(panorama.getLocation().description);
          console.log('location:', panorama.getLocation());
        }
        else {
          streetMapRef.current.handleChangeText('TOKYO');
        };

        
        }
      }, 8000);



    // 创建一个 MutationObserver 实例来隐藏 alpha banner
    const alphaBannerObserver = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
              const alphaBanner = document.querySelector('.vAygCK-api-load-alpha-banner');
              if (alphaBanner) {
                  alphaBanner.style.display = 'none';
                  // 找到元素后停止观察
                  observer.disconnect();
              }
          }
      }
    });
    // 配置观察器选项
    const alphaBannerConfig = { childList: true, subtree: true };
    // 开始观察
    alphaBannerObserver.observe(document.body, alphaBannerConfig);

    // Cleanup on unmount
    return () => {
      alphaBannerObserver.disconnect();
      clearInterval(intervalMapMove);
      clearInterval(intervalStreetPov);
      clearInterval(intervalStreetPosition);
    };
  }, [map3DElementUp, map3DElementDown, memoizedDefaultPosition]);

  return (
    <div className="map-container">
        <ThreeDMap id="map3DLeft" defaultPosition={memoizedDefaultPosition} isUp={true} ref = {ThreeDMapUpRef}/>
        <StreetMap defaultPosition={memoizedDefaultPosition} ref = {streetMapRef}/>
        <ThreeDMap id="map3DRight" defaultPosition={memoizedDefaultPosition} isUp={false} ref = {ThreeDMapDownRef}/>
    </div>
  );
};

export default Map;