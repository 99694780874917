import React, { useEffect, forwardRef, useState } from 'react';
import '../../css/map.css';

const ThreeDMap = forwardRef(({defaultPosition, isUp}, ref) => {

    const divClass = isUp ? 'map-up' : 'map-down';
    const defaultRoll = isUp ? '180' : '0';

    const defaultCenter = `${defaultPosition.lat}, ${defaultPosition.lng}, ${defaultPosition.alt}`;
    const [map3DElement, setMap3DElement] = useState(null);

    useEffect(() => {
        const init = async () => {

        document.querySelector(`.`+divClass).addEventListener('wheel', function(event) {
            // 阻止事件冒泡到整个页面
            event.stopPropagation();
        }, { passive: false });

        if (ref.current) {
        const mapElement = ref.current.querySelector('gmp-map-3d');
        setMap3DElement(mapElement);
        }
    };

    init();

    if (ref.current) {
        if (map3DElement) {
            const center = map3DElement.getAttribute('center');
            console.log('Initial center:', center);

            //camera restriction
            customElements.whenDefined(map3DElement.localName).then(() => {
            // map3DElement.bounds = {south: 34.8, west: 138.4, north: 36.2, east: 139.8}
            map3DElement.bounds = {south: 30, west: 130, north: 40, east:140}
            });

            // 在组件卸载时断开观察器
            return () => {
                // centerObserver.disconnect();
            };
        }
    }
        
    }, [ref, map3DElement, divClass]);


    return (
        <div className={divClass} ref={ref}>
            <gmp-map-3d center={defaultCenter} tilt="87" range="400" roll={defaultRoll} style={{ width: '100%', height: '100%' }}></gmp-map-3d>
        </div>
    );
});

export default ThreeDMap;