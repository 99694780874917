import React, { useEffect, forwardRef, useState, useImperativeHandle } from 'react';
import '../../css/map.css';

const StreetMap = forwardRef(({defaultPosition}, ref) => {
    const [locationDescription, setLocationDescription] = useState("TOKYO"); // 初始化状态
  
    useEffect(() => {
        
    }, []);
  
    const handleChangeText = (desc) => {
      setLocationDescription(desc); // 修改文本值
    };
  
    useImperativeHandle(ref, () => ({
      handleChangeText,
    }));
  
    return (
      <div className="map-center" ref={ref}>
        <div id="pano" className='map-center-inside'/>
        <p className="location-description">{locationDescription}</p>
      </div>
    );
});

export default StreetMap;