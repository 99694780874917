import Lenis from '@studio-freight/lenis'
import { Flip } from 'gsap/Flip';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Flip);

// Text element that moves horizontally as we scroll
let heading = {
    el: document.querySelector('.heading'),
    main: document.querySelector('.heading .heading__main'),
};


// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling
const initSmoothScrolling = () => {
	lenis = new Lenis({
		lerp: 0.1,
		smooth: true,
	});
	const scrollFn = (time) => {
		lenis.raf(time);
		requestAnimationFrame(scrollFn);
	};
	requestAnimationFrame(scrollFn);

    // Item instances (Item is the .content > figure.item)

    heading = {
        el: document.querySelector('.heading'),
        main: document.querySelector('.heading .heading__main'),
    };
};

// ScrollTrigger animations for scrolling
const animateOnScroll = (items) => {

	items.forEach((item) => {
        
		gsap.set(item.imageInner, {transformOrigin: '50% 0%'});

        gsap.timeline({
			scrollTrigger: {
				trigger: item.el,
                start: 'top bottom',
				end: 'bottom top',
				scrub: true
			}
		})
		.addLabel('start', 0)
        // scale up the inner image
        .to(item.imageInner, {
			ease: 'none',
			scaleY: 2.4,
            scaleX: 1.2,
            opacity: 0
		}, 'start')
        // translate the title and number
		.to([item.title, item.number], {
			ease: 'none',
			yPercent: -150,
		}, 'start')
        // translate the inner title/number (overflow is hidden so they get hidden)
        .to([item.titleInner, item.numberInner], {
            scrollTrigger: {
                trigger: item.el,
                start: 'top bottom',
				end: 'top 20%',
				scrub: true,
            },
			ease: 'expo.in',
            yPercent: -100
		}, 'start')
        /*
        .to(item.description, {
            scrollTrigger: {
                trigger: item.el,
                start: 'top bottom',
				end: 'bottom top',
				scrub: true,
            },
			ease: 'none',
            yPercent: 100
		}, 'start')
        */
        
	});

    // animate the heading element as we scroll (horizontally)
    let windowWidth = window.innerWidth;
    gsap.to(heading.main, {
        scrollTrigger: {
            start: 0,
            end: 'max',
            scrub: true
        },
        ease: 'none',
        x: () => -heading.main.offsetWidth - (13.25*windowWidth/100 + 25*windowWidth/100 + windowWidth/100) + windowWidth
    });
};



// Js code for svg video 
// JS to detect preview
// const isPreview = location.href.includes('fullcpgrid');
// const previewContainer = document.querySelector('.container--preview');
// const noPreviewContainer = document.querySelector('.container--no-preview');

// if (isPreview) {
//   noPreviewContainer.style.display = "none";
// } else {
//   previewContainer.style.display = "none";
// }

// JS to keep dither from breaking at different pixel ratios

// const ditherImages = document.querySelectorAll('.ditherImage');

// function setDitherImageSizesToPixelRatio() {
//   let size = 8 / window.devicePixelRatio * (isPreview ? 2 : 1);
//   console.log('new size ', size);
//   Array.from(ditherImages).forEach(img => {
//     img.setAttribute('width', size);
//     img.setAttribute('height', size);
//   })
// }

// setDitherImageSizesToPixelRatio();
// window.addEventListener("resize", setDitherImageSizesToPixelRatio);

export {
    initSmoothScrolling,
    animateOnScroll,
};