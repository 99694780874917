import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const ThreeScene = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    // Set up sizes
    const width = window.innerWidth;
    const height = window.innerHeight;
    const depth = 800;

    // Create renderer
    const renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    containerRef.current.appendChild(renderer.domElement);
    // 创建纹理加载器
    const textureLoader = new THREE.TextureLoader();

    // Create scene
    const scene = new THREE.Scene();


    // 将12.jpg设置为场景背景
    textureLoader.load(`${process.env.PUBLIC_URL}/images/threeBackground.jpg`, (texture) => {
      scene.background = texture;
    });

    // Create camera
    const camera = new THREE.PerspectiveCamera(45, width / height, 1, 5000);
    camera.position.set(0, 0, 1000);

    // Create ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    // Add fog
    scene.fog = new THREE.FogExp2(0xFFFFFF, 0.0001);

    // Create geometry and material for eyeball
    
    const eyeballGeometry = new THREE.SphereGeometry(100);
    const eyeballTexture = textureLoader.load(`${process.env.PUBLIC_URL}/texture/eyeball.jpg`);
    const eyeballMaterial = new THREE.MeshBasicMaterial({ map: eyeballTexture });
    const eyeball = new THREE.Mesh(eyeballGeometry, eyeballMaterial);

    // initial eyeball position
    eyeball.position.set(0, 0, 0);
    eyeball.scale.set(1, 0.5, 1);
    eyeball.rotation.set(0, 180, 0);

    scene.add(eyeball);

    // Mouse move handler
    const onMouseMove = (event) => {
      const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
      const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
      eyeball.position.x = mouseX * width / 2;
      eyeball.position.y = mouseY * height / 2;
    };
    window.addEventListener('mousemove', onMouseMove);


    // initial images
    const imageGeometry = new THREE.PlaneGeometry(128, 128);
    const imageTextures = Array.from({ length: 10 }, (_, index) => {
      return textureLoader.load(`${process.env.PUBLIC_URL}/texture/${index + 1}.jpg`);
    });
    const imageMaterials = imageTextures.map((texture) => {
      return new THREE.MeshBasicMaterial({ map: texture });
    });

    let images = [];
    const maxCount = 5;

    // Function to shoot images
    const shootImage = () => {
      if (images.length >= maxCount) {
        return;
      }
      const image = new THREE.Mesh(imageGeometry, imageMaterials[Math.floor(Math.random() * 10)]);
      image.position.set(
        eyeball.position.x,
        eyeball.position.y,
        eyeball.position.z + 100
      );
      image.rotation.set(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
      );
      image.scale.set(
        Math.random() * 0.8 + 0.2,
        Math.random() * 0.8 + 0.2,
        Math.random() * 0.8 + 0.2
      );
      images.push(image);
      scene.add(image);
    };

    // Set interval to shoot images
    const shootInterval = setInterval(shootImage, 300);


    // Animation function
    let eyeballRotationDirection = 1;
    const animate = () => {
      requestAnimationFrame(animate);

      if (eyeball.rotation.y > 180.5 || eyeball.rotation.y < 179.5) {
        eyeballRotationDirection = -eyeballRotationDirection;
      }
      eyeball.rotation.y += 0.002 * eyeballRotationDirection;
      
      // Update images
      images.forEach((image) => {
        image.position.x += image.position.x * -0.001;
        image.position.y += image.position.y * -0.001;
        image.position.z += 5;
        image.rotation.x += 0.01;
        image.rotation.y += 0.01;
        if (image.position.z > depth) {
          // remove image from images
          images = images.filter((img) => img !== image);
          scene.remove(image);
        }
      });

      renderer.render(scene, camera);
    };
    animate();


    // Resize handler
    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight * 2);
    };
    window.addEventListener('resize', onWindowResize);
    onWindowResize();

    // Cleanup on unmount
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onWindowResize);
      clearInterval(shootInterval);
      if (container){
        container.removeChild(renderer.domElement);
      }
    };
  }, []);

  return <div ref={containerRef} 
  style={{
    width: '100vw',
    height: '200vh', // 设置为窗口高度的2倍
    position: 'relative', // 确保覆盖整个视口
    top: 0,
    left: 0,
  }}/>;
};

export default ThreeScene;