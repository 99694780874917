import React from 'react';

const VideoAndFilter = () => {

    const videoLeft = `${process.env.PUBLIC_URL}/videos/head_left.mp4`;
    const videoRight = `${process.env.PUBLIC_URL}/videos/head_right.mp4`;

    return (
        <div className="container container--no-preview">
        <video className="video-left" loop autoPlay muted preload="none">
          {/* <source src="https://videos.pexels.com/video-files/2397239/2397239-sd_960_506_24fps.mp4" type="video/mp4" /> */}
          <source src={videoLeft} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="video-right" loop autoPlay muted preload="none">
          {/* <source src="https://videos.pexels.com/video-files/14952031/14952031-sd_960_540_30fps.mp4" type="video/mp4" /> */}
          <source src={videoRight} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <svg>
        <filter id="filter1" colorInterpolationFilters="sRGB" x="0" y="0" width="100%" height="100%">
          <feFlood floodColor="#000000" floodOpacity="0.50" x="0%" y="0%" result="flood" />
          <feBlend mode="normal" x="0%" y="0%" in="SourceGraphic" in2="flood" result="blend1" />
          <feImage className="ditherImage" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAA5ElEQVQYlQXBgQbCUABA0fdrk0ySSZJJkiRJMjOTTGZmkiRJZiYzyczMzGQmfdrtHPH7/TgcDuR5zna7pWka9vs9aZqyXq8R0+mU5/OJoihcLhfG4zFBENDtdjmdToj3+81yueTz+WCaJnEcM5/PKcsSXdcRsizjeR6j0YjH40Gr1cJxHAaDAbfbDVHXNbvdjiRJWK1WfL9fLMsiyzI2mw1CVVV836fT6XA8HplMJoRhSK/X43w+I6IoYjabURQFmqbxer1YLBZUVYVhGAhJkrBtm36/z/V6pd1u47ouw+GQ+/3OH4/Fn8FvF/NxAAAAAElFTkSuQmCC" x="0" y="0" width="4" height="4" crossOrigin="anonymous" result="image1" />
          <feTile x="0" y="0" in="image1" result="tile" />
          <feBlend mode="overlay" x="0%" y="0%" in="blend1" in2="tile" result="blend2" />
          <feColorMatrix type="saturate" values="0" />
          <feComponentTransfer>
            <feFuncR type="discrete" tableValues="0 0" />
            <feFuncG type="discrete" tableValues="0 1" />
            <feFuncB type="discrete" tableValues="0 1" />
          </feComponentTransfer>
        </filter>
        <filter id="filter2" colorInterpolationFilters="sRGB" x="0" y="0" width="100%" height="100%">
          <feFlood floodColor="#000000" floodOpacity="0.50" x="0%" y="0%" result="flood" />
          <feBlend mode="normal" x="0%" y="0%" in="SourceGraphic" in2="flood" result="blend1" />
          <feImage className="ditherImage" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAA5ElEQVQYlQXBgQbCUABA0fdrk0ySSZJJkiRJMjOTTGZmkiRJZiYzyczMzGQmfdrtHPH7/TgcDuR5zna7pWka9vs9aZqyXq8R0+mU5/OJoihcLhfG4zFBENDtdjmdToj3+81yueTz+WCaJnEcM5/PKcsSXdcRsizjeR6j0YjH40Gr1cJxHAaDAbfbDVHXNbvdjiRJWK1WfL9fLMsiyzI2mw1CVVV836fT6XA8HplMJoRhSK/X43w+I6IoYjabURQFmqbxer1YLBZUVYVhGAhJkrBtm36/z/V6pd1u47ouw+GQ+/3OH4/Fn8FvF/NxAAAAAElFTkSuQmCC" x="0" y="0" width="4" height="4" crossOrigin="anonymous" result="image1" />
          <feTile x="0" y="0" in="image1" result="tile" />
          <feBlend mode="overlay" x="0%" y="0%" in="blend1" in2="tile" result="blend2" />
          <feColorMatrix type="saturate" values="0" />
          <feComponentTransfer>
            <feFuncR type="discrete" tableValues="0 0.5 1" />
            <feFuncG type="discrete" tableValues="0 0.3" />
            <feFuncB type="discrete" tableValues="0 0.2" />
          </feComponentTransfer>
        </filter>
        <filter id="filterMapUp" colorInterpolationFilters="sRGB" x="0" y="0%" width="100%" height="90%">
          <feFlood floodColor="#000000" floodOpacity="0.50" x="0%" y="0%" result="flood" />
          <feBlend mode="normal" x="0%" y="0%" in="SourceGraphic" in2="flood" result="blend1" />
          <feImage className="ditherImage" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAA5ElEQVQYlQXBgQbCUABA0fdrk0ySSZJJkiRJMjOTTGZmkiRJZiYzyczMzGQmfdrtHPH7/TgcDuR5zna7pWka9vs9aZqyXq8R0+mU5/OJoihcLhfG4zFBENDtdjmdToj3+81yueTz+WCaJnEcM5/PKcsSXdcRsizjeR6j0YjH40Gr1cJxHAaDAbfbDVHXNbvdjiRJWK1WfL9fLMsiyzI2mw1CVVV836fT6XA8HplMJoRhSK/X43w+I6IoYjabURQFmqbxer1YLBZUVYVhGAhJkrBtm36/z/V6pd1u47ouw+GQ+/3OH4/Fn8FvF/NxAAAAAElFTkSuQmCC" x="0" y="0" width="8" height="8" crossOrigin="anonymous" result="image1" />
          <feTile x="0" y="0" in="image1" result="tile" />
          <feBlend mode="overlay" x="0%" y="0%" in="blend1" in2="tile" result="blend2" />
          <feColorMatrix type="saturate" values="1" />
          <feComponentTransfer>
            <feFuncR type="discrete" tableValues="0 0.8" />
            <feFuncG type="discrete" tableValues="0 0.5" />
            <feFuncB type="discrete" tableValues="0 0.8" />
          </feComponentTransfer>
        </filter>
        <filter id="filterMapCenter" colorInterpolationFilters="sRGB" x="0" y="0%" width="100%" height="90%">
          <feFlood floodColor="#000000" floodOpacity="0.50" x="0%" y="0%" result="flood" />
          <feBlend mode="normal" x="0%" y="0%" in="SourceGraphic" in2="flood" result="blend1" />
          <feImage className="ditherImage" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAA5ElEQVQYlQXBgQbCUABA0fdrk0ySSZJJkiRJMjOTTGZmkiRJZiYzyczMzGQmfdrtHPH7/TgcDuR5zna7pWka9vs9aZqyXq8R0+mU5/OJoihcLhfG4zFBENDtdjmdToj3+81yueTz+WCaJnEcM5/PKcsSXdcRsizjeR6j0YjH40Gr1cJxHAaDAbfbDVHXNbvdjiRJWK1WfL9fLMsiyzI2mw1CVVV836fT6XA8HplMJoRhSK/X43w+I6IoYjabURQFmqbxer1YLBZUVYVhGAhJkrBtm36/z/V6pd1u47ouw+GQ+/3OH4/Fn8FvF/NxAAAAAElFTkSuQmCC" x="0" y="0" width="6" height="6" crossOrigin="anonymous" result="image1" />
          <feTile x="0" y="0" in="image1" result="tile" />
          <feBlend mode="overlay" x="0%" y="0%" in="blend1" in2="tile" result="blend2" />
          <feColorMatrix type="saturate" values="0.5" />
          <feComponentTransfer>
            <feFuncR type="discrete" tableValues="0 1" />
            <feFuncG type="discrete" tableValues="0 1" />
            <feFuncB type="discrete" tableValues="0 0.2" />
          </feComponentTransfer>
        </filter>
        
        <filter id="filterMapDown" colorInterpolationFilters="sRGB" x="0" y="0%" width="100%" height="90%">
          <feFlood floodColor="#000000" floodOpacity="0.50" x="0%" y="0%" result="flood" />
          <feBlend mode="normal" x="0%" y="0%" in="SourceGraphic" in2="flood" result="blend1" />
          <feImage className="ditherImage" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAA5ElEQVQYlQXBgQbCUABA0fdrk0ySSZJJkiRJMjOTTGZmkiRJZiYzyczMzGQmfdrtHPH7/TgcDuR5zna7pWka9vs9aZqyXq8R0+mU5/OJoihcLhfG4zFBENDtdjmdToj3+81yueTz+WCaJnEcM5/PKcsSXdcRsizjeR6j0YjH40Gr1cJxHAaDAbfbDVHXNbvdjiRJWK1WfL9fLMsiyzI2mw1CVVV836fT6XA8HplMJoRhSK/X43w+I6IoYjabURQFmqbxer1YLBZUVYVhGAhJkrBtm36/z/V6pd1u47ouw+GQ+/3OH4/Fn8FvF/NxAAAAAElFTkSuQmCC" x="0" y="0" width="8" height="8" crossOrigin="anonymous" result="image1" />
          <feTile x="0" y="0" in="image1" result="tile" />
          <feBlend mode="overlay" x="0%" y="0%" in="blend1" in2="tile" result="blend2" />
          <feColorMatrix type="saturate" values="0.5" />
          <feComponentTransfer>
            <feFuncR type="discrete" tableValues="0 0.5" />
            <feFuncG type="discrete" tableValues="0 0.8" />
            <feFuncB type="discrete" tableValues="0 0.8" />
          </feComponentTransfer>
        </filter>
        </svg>
      </div>
    );
};
    
export default VideoAndFilter;