import React, { useEffect, useRef, forwardRef, useContext} from 'react';
import { ItemContext } from './ItemContext';

const ImageInner = forwardRef(({ imageUrl }, ref) => {
    return (
      imageUrl.endsWith('.mp4') ? (
        <video autoPlay loop muted playsInline style={{ width: '100%', height: '100%' }}>
          <source src={imageUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div style={{ backgroundImage: `url(${imageUrl})`, width: '100%', height: '100%' }}></div>
      )
    );
});

const Item = ({number, title, description, imageUrl}) => {
  const { setItems } = useContext(ItemContext);

  // 使用 useRef 来获取 DOM 元素的引用
  const elRef = useRef(null);
  const captionRef = useRef(null);

  const imageWrapRef = useRef(null);
  const imageRef = useRef(null);
  const imageInnerRef = useRef(null);
  const titleRef = useRef(null);
  const titleInnerRef = useRef(null);
  const numberRef = useRef(null);
  const numberInnerRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    // 在组件挂载后执行的逻辑
    const DOM = {
      el: elRef.current,
      caption: captionRef.current,
      imageWrap: imageWrapRef.current,
      image: imageRef.current,
      imageInner: imageInnerRef.current,
      title: titleRef.current,
      titleInner: titleInnerRef.current,
      number: numberRef.current,
      numberInner: numberInnerRef.current,
      description: descriptionRef.current,
    };

    setItems(prevItems => [...prevItems, DOM]);

    // 你可以在这里添加任何需要的初始化逻辑

    const currentEl = elRef.current;

    // 清理函数
    return () => {
      // 在组件卸载时执行的逻辑
      
      setItems(prevItems => prevItems.filter(item => item.el !== currentEl));
    };
  }, [setItems]);

  return (
    <figure className="item" ref={elRef}>
        <figcaption className="item__caption" ref={captionRef}>
            <span className="item__caption-number oh" ref={numberRef}><span className="oh__inner" ref={numberInnerRef}>
                {number}
            </span></span>
            <h2 className="item__caption-title oh" ref={titleRef}><span className="oh__inner" ref={titleInnerRef}>
                {title}
            </span></h2>
            <p className="item__caption-description" ref={descriptionRef}>
                {description}
            </p>
        </figcaption>
        <div className="item__image-wrap" ref={imageWrapRef}>
            <div className="item__image" ref={imageRef}>
              <ImageInner imageUrl={imageUrl} ref={imageInnerRef} />
            </div>
        </div>
    </figure>
  );
};

export default Item;